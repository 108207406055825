import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Navigation from './navigation'
import { toKebabCase } from '../helpers'

import * as style from '../styles/post.module.css'

const Post = ({
  coverImage,
  title,
  date,
  path,
  author,
  excerpt,
  tags,
  html,
  previousPost,
  nextPost,
}) => {
  const previousPath = previousPost && previousPost.frontmatter.path
  const previousLabel = previousPost && previousPost.frontmatter.title
  const nextPath = nextPost && nextPost.frontmatter.path
  const nextLabel = nextPost && nextPost.frontmatter.title

  return (
    <div className={style.postContainer}>
      <div className={style.post}>
        <div className={style.postContent}>
        {tags ? (
          <div className={style.tags}>
            {tags.map(tag => (
                 <span className={style.tag}>#{tag}</span>
        ))}
      </div>
      ) : null}
        <h1 className={style.title}>
          {title}
        </h1>
        <div className={style.excerpt}>
        {excerpt ? (
          <p>{excerpt}</p>
        ) : null}
        </div>
        <div className={style.meta}>
          {author && <>— Written by {author}</>}
          {coverImage && (
            <Link to={path}>
            <Img
              fluid={coverImage.childImageSharp.fluid}
              className={style.coverImage}
            />
            </Link>
          )}
          </div>

          <div className={style.contentText} dangerouslySetInnerHTML={{ __html: html }} />
              <Navigation
                previousPath={previousPath}
                previousLabel={previousLabel}
                nextPath={nextPath}
                nextLabel={nextLabel}
              />
        </div>
      </div>
    </div>
  )
}

Post.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  author: PropTypes.string,
  excerpt: PropTypes.string,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
}

export default Post
