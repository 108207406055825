// extracted by mini-css-extract-plugin
export var contentText = "post-module--contentText--5C4Vu";
export var coverImage = "post-module--coverImage--kWdhT";
export var excerpt = "post-module--excerpt--G3d-u";
export var meta = "post-module--meta--6-j+j";
export var post = "post-module--post--3LtSx";
export var postContainer = "post-module--postContainer--8n3QF";
export var postContent = "post-module--postContent--vtzNe";
export var readMore = "post-module--readMore--uB4bY";
export var tag = "post-module--tag---UZTN";
export var tags = "post-module--tags--1FKmA";
export var title = "post-module--title--4pURA";